import React from 'react'
import { Helmet } from 'react-helmet'
import { Twitter, Facebook, Linkedin } from 'react-social-sharing'
import MatchLayout from '../layouts/MatchLayout'
import I18n from '../utils/I18n'
import SocialShare from '../components/SocialShare'

const MatchTemplate = (props) => {
  const {pageContext: {lang, role, slug}} = props
  const i18n = new I18n({lang})

  const takeTheQuiz = () => {
    if (process.env.NODE_ENV === "production") {
      gtm.trackEvent({
        event: "eventTracker",
        eventCat: "Internal Link Clicks",
        eventAct: "Take the Quiz",
        eventLbl: "/career-path/quiz-values",
        nonInteraction: "false"
      })
    }

    window.location.href = "/career-path/quiz-values"
  }

  const trailheadUrl = process.env.GATSBY_ENV === 'production' ? 'https://trailhead.salesforce.com' : 'https://ext.trailhead.sfdc.sh'
  const s3Url = 'https://developer.salesforce.com/resources2/quizapp'

  const title = 'Trailblazer Career Match'
  const description = 'Identify your career matches in the Salesforce ecosystem - unlock your path to professional success with @Trailhead! Take the free quiz #TrailblazerCommunity at trailhead.com/career-match'
  const socialImage = role ? `${s3Url}/downloads/${role}.jpg` : `${s3Url}/home-social-image.jpg`
  const shareUrl = `${trailheadUrl}/${lang}/career-path/match`
  const url = role ? `${trailheadUrl}/${lang}/career-path/match/${role}` : shareUrl
  
  return (
    <MatchLayout lang={lang} slug={slug} title={title} description={description} url={url} socialImage={socialImage}>
      
      <div id="home">
        <div>
          <div style={{backgroundColor: 'rgb(241, 250, 255)', paddingBottom: '50px'}}>
            <div className="container" grid="align-center" axis="vertical" flow="wrap">
              <div className="slds-size_1-of-1 slds-medium-size_1-of-2" style={{paddingRight: '25px'}}>
                <h1 className="for-mobile" type-style="display-1" style={{paddingBottom: '0px !important', textAlign: 'left'}}>{i18n.text('match_title')}</h1>
                <p type-style="body-3" className="mb-5">{i18n.text('match_subtitle')}</p>
                <a className="btn btn-blue" onClick={takeTheQuiz} href={`/${lang}/career-path/quiz-values`}>{i18n.text('match_take_the_quiz_btn')}</a>
              </div>
              <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-p-top_x-large">
                <img className="move-up desktop-img" src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-main-img.webp" alt={i18n.text('match_hero_image_alt')} />
                <img className="move-up mobile-img" src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-main-mobile.webp" alt={i18n.text('match_hero_image_alt')} />
              </div>
            </div>
          </div>
          
          <div className="container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '35px', marginBottom: '75px'}}>
            <h2 type-style="display-3">{i18n.text('match_stats_title')}</h2>
            <p type-style="body-2" style={{maxWidth: '500px', textAlign: 'center'}}>{i18n.text('match_stats_subtitle')}</p>
            <div className="icon-block">
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-rocket.png" alt="Illustration of rocket ship" className="icon" />
                <h2 type-style="display-4" style={{paddingBottom: '0px !important'}}>{i18n.text('match_stat_1_title')}</h2>
                <p type-style="body-2" style={{paddingTop: '0px !important'}}>{i18n.text('match_stat_1_subtitle')}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-portfolio.png" alt="Illustration of briefcase" className="icon" />
                <h2 type-style="display-4" style={{paddingBottom: '0px !important'}}>{i18n.text('match_stat_2_title')}</h2>
                <p type-style="body-2" style={{paddingTop: '0px !important'}}>{i18n.text('match_stat_2_subtitle')}</p>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-skills.png" alt="Illustration of envelope filled with money" className="icon" />
                <h2 type-style="display-4" style={{paddingBottom: '0px !important'}}>{i18n.text('match_stat_3_title')}</h2>
                <p type-style="body-2" style={{paddingTop: '0px !important'}}>{i18n.text('match_stat_3_subtitle')}</p>
              </div>
            </div>
          </div>
          <div style={{padding: '75px 0px', background: 'rgb(217, 242, 249)'}}>
            <div className="container">
              <div grid="align-center" flow="wrap" axis="vertical" style={{alignItems: 'center'}}>
                <div size="1:1 1:2@medium 2:5@large" style={{textAlign: 'center'}}>
                  <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-astro.webp" alt="Astro in Trailhead hat with walking stick." style={{maxWidth: '350px'}}/>
                </div>
                <div size="1:1 1:2@medium 2:5@large" style={{paddingLeft: '32px'}}>
                  <h2 type-style="display-4">{i18n.text('match_discover_title')}</h2>
                  <p type-style="body-3" className="mb-5" style={{color: 'rgb(3, 45, 96) !important'}}>{i18n.text('match_discover_subtitle')}</p>
                  <a className="btn btn-blue" onClick={takeTheQuiz} href={`/${lang}/career-path/quiz-values`}>{i18n.text('match_discover_button')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', marginTop: '50px', marginBottom: '75px'}} className="container my-5">
        <h2 type-style="display-3" style={{textAlign: 'center'}}>{i18n.text('match_videos_title')}</h2>
        <div className="icon-block mobile-flex" style={{alignItems: 'center', justifyContent: 'space-evenly'}}>
          <img
            style={{width: '100%', margin: 'auto', display: 'block'}}
            className="vidyard-player-embed"
            src="https://play.vidyard.com/V7k2KqCJiWHFupU4DPBSmt.jpg"
            data-uuid="V7k2KqCJiWHFupU4DPBSmt"
            data-v="4"
            data-type="lightbox"
            />
          <img
            style={{width: '100%', margin: 'auto', display: 'block'}}
            className="vidyard-player-embed"
            src="https://play.vidyard.com/BifVeLXBhAN5m52dfaRSRZ.jpg"
            data-uuid="BifVeLXBhAN5m52dfaRSRZ"
            data-v="4"
            data-type="lightbox"
            />
          <img
            style={{width: '100%', margin: 'auto', display: 'block'}}
            className="vidyard-player-embed"
            src="https://play.vidyard.com/VP7iWaX53jyM8dQX6mC2RS.jpg"
            data-uuid="VP7iWaX53jyM8dQX6mC2RS"
            data-v="4"
            data-type="lightbox"
            />
        </div>
      </div>

      <div style={{padding: '32px 0', background: '#16325c'}}>
        <div className="container" style={{textAlign: 'center', padding: '25px 0'}}>
          <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/home-backpack.png" alt="Illustrated icon of a backpack with patches." className="icon"/>
          <h3 type-style="display-6" className="text-white">{i18n.text('match_explore_title')}</h3>
          <p type-style="body-3" className="mb-5 text-white add-mobile-padding" style={{maxWidth: '450px', textAlign: 'center', margin: '0 auto 30px'}}>{i18n.text('match_explore_subtitle')}</p>
          <a className="btn btn-blue" href="https://trailhead.com/career-path" target="_blank">{i18n.text('match_explore_button')}</a>
        </div>
      </div>

      <div style={{padding: 0, background: '#0176d3'}}>
        <div className="container social-share-container">
          <h3 type-style="display-5" className="text-white">{i18n.text('match_share_title')}</h3>
          <p type-style="body-1" className="text-white" style={{paddingLeft: '10px'}}>{i18n.text('match_share_subtitle')}</p>
          <div id="social-sharing" className="social-share" data-v-app="">
            <div>
              <SocialShare lang={lang} type="home" network="facebook" url={shareUrl} />
              <SocialShare lang={lang} type="home" network="twitter" url={shareUrl} />
              <SocialShare lang={lang} type="home" network="linkedin" url={shareUrl} />
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{textAlign: 'center', padding: '10px 0'}}>
        <p type-style="body-3" className="add-mobile-padding">
          <a href="https://sforce.co/jobs-research-2021" target="_blank">{i18n.text('match_source_text')}</a>
        </p>
      </div>

    </MatchLayout>
  )
}

export default MatchTemplate